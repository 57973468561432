var app = angular.module('vlilleApp', ['ngRoute']);

app.config(function($routeProvider) {
  $routeProvider
    .when('/', {
      templateUrl: 'assets/js/app/views/vlille.html',
      controller: 'vlilleController'
    })
    .when('/404', {
      templateUrl: 'assets/js/app/views/404.html'
    })
    .otherwise({
      redirectTo: '/404'
    });
});
